<template>
  <!-- <DetailForm persistent="toggle_alarm_panel"> -->
  <!-- <DetailFormToolbar /> -->
  <!-- </DetailForm> -->
  <!-- </DetailForm> -->
  <div>
    <TogglePanel title="panel_properties">
      <div class="inner-panel">
        <DetailFormToolbar />
      </div>
    </TogglePanel>
  </div>
</template>

<script>
// import DetailForm from "@/components/control-sidebar/property-editors/detail-toolbar-form.vue";
import DetailFormToolbar from "@/components/control-sidebar/property-editors/detail-form-toolbar.vue";

import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";

export default {
  name: "EquipmentToolbarForm",
  components: {
    TogglePanel,
    // DetailForm,
    DetailFormToolbar
  }
};
</script>

<style scoped>
.inner-panel {
  padding: 10px;
}
</style>
