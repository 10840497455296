<template>
  <span>
    <div class="btn-group" style="vertical-align: top;">
      <div
        type="button"
        :class="`btn btn-${type} dropdown-toggle`"
        data-toggle="dropdown"
        @click="updatePosition"
      >
        <i class="fa fa-refresh fa-spin" v-if="downloading"></i>
        <i class="fa fa-download" v-else></i>
      </div>
      <ul class="dropdown-menu" :class="{ 'fix-right': this.offsetLeft > 250 }">
        <li v-if="xls">
          <a href="#" v-on:click="onClick('xls')">Microsoft Excel (.xls) </a>
          <span
            class="btn-sort btn btn-xs"
            @click.stop.prevent="reverse_xls = !reverse_xls"
            ><i
              class="fa"
              :class="reverse_xls ? 'fa-sort-alpha-asc' : 'fa-sort-alpha-desc'"
            ></i
          ></span>
        </li>
        <li v-if="csv">
          <a href="#" v-on:click="onClick('csv')"
            >Comma-separated Values (.csv)
          </a>
          <span
            class="btn-sort btn btn-xs"
            @click.stop.prevent="reverse_csv = !reverse_csv"
            ><i
              class="fa"
              :class="reverse_csv ? 'fa-sort-alpha-asc' : 'fa-sort-alpha-desc'"
            ></i
          ></span>
        </li>
      </ul>
    </div>
  </span>
</template>

<script>
export default {
  name: "DownloadButton",
  props: {
    tableId: String,
    filename: String,
    type: {
      type: String,
      required: false,
      default: "default"
    },
    downloading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    csv: {
      type: Boolean,
      required: false,
      default: () => true
    },
    xls: {
      type: Boolean,
      required: false,
      default: () => true
    }
  },
  data() {
    return {
      fileType: "",
      reverse_xls: false,
      reverse_csv: false,
      offsetLeft: 0
    };
  },
  computed: {
    fname: function() {
      var self = this;
      var fn = self.filename;
      return (
        fn ||
        new Date()
          .toISOString()
          .replace(/[\-\:T]/g, "")
          .split(".")[0]
          .substr(2)
      );
    }
  },
  watch: {
    downloading(n) {
      if (n) {
        if (this.fileType == "xls") {
          setTimeout(() => {
            this.downloadAsXLS();
          }, 1000);
        } else if (this.fileType == "csv") {
          setTimeout(() => {
            this.downloadAsCSV();
          }, 1000);
        }
      }
      this.fileType = "";
    }
  },
  methods: {
    onClick(fileType) {
      if (this.$store.getters["dashboard/mode"] == "editor") return;
      if (!this.fileType) {
        this.fileType = fileType;
        this.$emit("ready");
      }
    },
    downloadAsXLS() {
      if (!this.tableId) {
        this.$root.$emit("toolbar:download", "xls");
        this.$emit("done");
        return;
      }
      this.$utils.HTMLTable2XLS(
        document.getElementById(this.tableId),
        this.fname,
        () => {
          this.$emit("done");
        },
        this.reverse_xls
      );
    },
    downloadAsCSV() {
      if (!this.tableId) {
        this.$root.$emit("toolbar:download", "csv");
        this.$emit("done");
        return;
      }
      this.$utils.HTMLTable2CSV(
        document.getElementById(this.tableId),
        this.fname,
        () => {
          this.$emit("done");
        },
        this.reverse_csv
      );
    },
    updatePosition() {
      this.offsetLeft = this.$el.offsetLeft;
    }
  },
  mounted() {
    this.updatePosition();
  }
};
</script>

<style scoped>
.fix-right {
  right: 0;
  left: auto;
  margin-top: 0px;
}

.dropdown-menu li {
  white-space: nowrap;
  position: relative;
}

.dropdown-menu > li > a {
  display: block;
  padding: 3px 30px 3px 10px;
}

.btn-sort {
  position: absolute;
  top: 3px;
  right: 0px;
  color: #337ab7;
}

.btn-sort:hover {
  cursor: pointer;
  opacity: 0.8;
  color: #f39c12;
}
</style>
