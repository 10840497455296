import { render, staticRenderFns } from "./detail-form-toolbar.vue?vue&type=template&id=5a954b62&scoped=true&"
import script from "./detail-form-toolbar.vue?vue&type=script&lang=js&"
export * from "./detail-form-toolbar.vue?vue&type=script&lang=js&"
import style0 from "./detail-form-toolbar.vue?vue&type=style&index=0&id=5a954b62&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a954b62",
  null
  
)

export default component.exports